import Header_ from "./Header/Header";
import Footer_ from "./Footer/Footer";
import Banner_ from "./Banner/Banner";
import InfoCard_ from "./InfoCard/InfoCard";
import Button_ from "./Button/Button";
import Tabs_ from "./Tabs/Tabs";
import ContactDetailsForm_ from "./ContactDetailsForm/ContactDetailsForm";
import Itinerary_ from "./Itinerary/Itinerary";
import SliderBanner_ from "./SliderBanner/SliderBanner";
import CoverSlider_ from "./CoverSlider/CoverSlider";
import EnquiryForm_ from "./EnquiryForm/EnquiryForm";
import ThankYouPopUp_ from "./ThankYouPopUp/index";
import ActionCard_ from "./ActionCard/ActionCard";
import Loader_ from "./Loader/Loader";

export const Header = Header_;
export const Footer = Footer_;
export const Banner = Banner_;
export const InfoCard = InfoCard_;
export const Button = Button_;
export const Tabs = Tabs_;
export const ContactDetailsForm = ContactDetailsForm_;
export const Itinerary = Itinerary_;
export const SliderBanner = SliderBanner_;
export const CoverSlider = CoverSlider_;
export const EnquiryForm = EnquiryForm_;
export const ThankYouPopUp = ThankYouPopUp_;
export const ActionCard = ActionCard_;
export const Loader = Loader_;
