import { lazy } from "react";
import HelmetHOC from "../../utils/HelmetHOC";

const Home = lazy(() => import("./Home"));

const HomeRouteDetails = {
  path: "/",
  exact: true,
  render() {
    return HelmetHOC(Home, {
      title: "Classic Tanzania Safaris - Experience Wilderness - Luxury Safaris",
      description:
        "Planing a Safari in Africa? We build iternaries for all sorts of Safaris. One of the leading Safari organizers in Tanzania. Book your Safari now."
    });
  }
};

export { HomeRouteDetails };
