import { lazy } from "react";
import HelmetHOC from "../../utils/HelmetHOC";

const AboutUs = lazy(() => import("./AboutUs"));

const AboutUsRouteDetails = {
  path: "/about-us",
  exact: true,
  render() {
    return HelmetHOC(AboutUs, {
      title: "About us",
      description:
        "Planing a Safari in Africa? We build iternaries for all sorts of Safaris. One of the leading Safari organizers in Kenya & Tanzania. Book your Safari now"
    });
  }
};

export { AboutUsRouteDetails };
