import React, { Suspense } from "react";
import { Header, Footer, Loader } from "../../common/components/";
import Routes from "../Routes/Routes";
import "./PageLayout.scss";

const PageLayout = () => {
  return (
    <div className="page-layout">
      <Suspense fallback={<Loader />}>
        <Header />
        <Routes />
        <Footer />
      </Suspense>
    </div>
  );
};

export default PageLayout;
