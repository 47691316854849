import React from "react";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="loader-conatiner">
      <div className="loadingio-spinner-rolling-iv1ikupkl3i">
        <div className="ldio-6woaeq8kw1k">
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
