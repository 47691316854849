import React from 'react';
import { 
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
  Avatar
 } from '@material-ui/core';
 import Button from '../Button/Button';
import { withRouter } from 'react-router-dom';

import './ActionCard.scss';

const ActionCard = (props) => {
  const handleOnCardClick = () => {
    if(props.path) {
      props.history.push(props.path);
    }
  }

  const makeCardHeaderContent = () => (
    <div className={`action-card__header--content ${props.showHeaderAction ? 'space-between' : ''}`}>
      {props.title}
      {
        props.showHeaderAction &&
        <Button className="action-card__header--content__button primary"
          onClick={props.handleActionClick} >
            Know more
        </Button>
      }
    </div>
  );

  return (
    <Card className={props.className ? ` ${props.className} action-card--container` : 'action-card--container'} onClick={props.path ? handleOnCardClick : ''}>
      {
        props.src ?
        <CardMedia 
          className="action-card--card-media"
          image={props.src}
          title={props.imageTitle}/> : ''
      }
      {
        props.tag ?
        <div className="tag">
          {props.tag}
        </div> : ''
      }
      <CardContent>
        <CardHeader 
          avatar={props.cardIcon ? <Avatar>{props.cardIcon}</Avatar> : ''}
          title={makeCardHeaderContent()}
          subheader={props.subHeader || ''} />
        <Typography 
          className="action-card--description"
          variant="body2"
          color="textSecondary" 
          component="p">
            {props.description || ''}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default withRouter(ActionCard);