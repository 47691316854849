import { lazy } from "react";
import HelmetHOC from "../../utils/HelmetHOC";

const ContactUs = lazy(() => import("./ContactUs"));

const ContactUsRouteDetails = {
  path: "/contact-us",
  exact: true,
  render() {
    return HelmetHOC(ContactUs, {
      title: "Contact us",
      description:
        "Planing a Safari in Africa? We build iternaries for all sorts of Safaris. One of the leading Safari organizers in Kenya & Tanzania. Book your Safari now"
    });
  }
};

export { ContactUsRouteDetails };
