import { lazy } from "react";
import HelmetHOC from "../../utils/HelmetHOC";

const PackageDetails = lazy(() => import("./PackageDetails"));

const PackageDetailsRouteDetails = {
  path: "/details",
  exact: true,
  render() {
    return HelmetHOC(PackageDetails, {
      title: "Package Details",
      description:
        "Planing a Safari in Africa? We build iternaries for all sorts of Safaris. One of the leading Safari organizers in Kenya & Tanzania. Book your Safari now"
    });
  }
};

export { PackageDetailsRouteDetails };
