import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { Row, Col } from 'reactstrap';
import Button from '../Button/Button';
import { ThankYouPopUp } from '../';
import Validate from '../../../utils/validate';
import { contactUs } from '../../Api/commonApi';
import { withRouter } from 'react-router-dom';
import './EnquiryForm.scss';

class EnquiryForm extends Component {
    state = {
        fullName: '',
        email: '',
        contactNumber: '',
        message: '',
        isError: false,
        isButtonDisabled: true,
        showThankyouPopup: false
    };
    onChangeHandler = (e) => {
        let { name, value } = e.target;
        value = value.trim() !== '' ? value : '';

        if(this.isValueValid(name, value) ) {
            this.setState({
                [name]: value,
                isError: false
            },() => {
                this.enableDisableSubmitButton();
            });
        }
    }

    isValueValid = (name, value) => {
        switch(name) {
            case 'fullName':
                return Validate.Text(value);
            case 'contactNumber':
            default:
                return true;
        }
    }

    enableDisableSubmitButton = () => {
        const isButtonDisabled = (this.state.fullName && this.state.email && this.state.contactNumber ) ? false : true;
        this.setState({
            isButtonDisabled
        });
    }
    onSubmit = () => {
        console.log('props', this.props)
        if(Validate.Email(this.state.email) && this.state.contactNumber.length === 10) {
          const payload = {
            "name": this.state.fullName,
            "email": this.state.email,
            "contactNumber": this.state.contactNumber,
            "message": this.state.message
          }
          contactUs(payload)
          .then(resp => {
            console.log(resp)
            this.setState({
              showThankyouPopup: true
            })
          })
          .catch(error => {
            console.log(error)
            this.setState({
              showThankyouPopup: true
            })
          });
          //Submit the form
          this.setState({
              isError: false
          });
        } else {
            this.setState({
                isError: true
            });
        }
    }
    render() {
        return (
            <div className="enquiry-form_container">
                {this.state.showThankyouPopup && <ThankYouPopUp isOpen={true}
                  toggle={() => {
                    this.setState({ showThankyouPopup: !this.state.showThankyouPopup }, () => {
                      this.props.history.push('/')
                    })
                  }} />
                }
                <form noValidate>
                    <Row>
                        <Col sm="12">
                            <TextField
                                label="Full Name"
                                type="text"
                                fullWidth
                                name="fullName"
                                className="contact-us-text"
                                required
                                margin="normal"
                                onChange={this.onChangeHandler}
                                inputProps={{ maxLength: 50 }}
                                value={this.state.fullName}
                            />
                        </Col>
                        <Col sm="12">
                            <TextField
                                error={this.state.isError && !Validate.Email(this.state.email)}
                                helperText={this.state.isError && !Validate.Email(this.state.email) ? "Invalid Email" : ''}
                                required
                                id="standard-email-input"
                                label="Email"
                                type="email"
                                name="email"
                                fullWidth
                                className="contact-us-text"
                                margin="normal"
                                onChange={this.onChangeHandler}
                                inputProps={{ maxLength: 150 }}
                                value={this.state.email}
                            />
                        </Col>
                        <Col sm="12">
                            <TextField
                                error={this.state.isError && this.state.contactNumber.length < 10}
                                helperText={this.state.isError && this.state.contactNumber.length < 10 ? "Invalid Contact Number" : ''}
                                required
                                id="standard-number-input"
                                label="Contact Number"
                                type="text"
                                name="contactNumber"
                                fullWidth
                                className="contact-us-text"
                                margin="normal"
                                onChange={this.onChangeHandler}
                                inputProps={{ maxLength: 10 }}
                                value={this.state.contactNumber}
                            />
                        </Col>
                        <Col sm="12">
                            <TextField
                                id="standard-multiline-flexible"
                                label="Message"
                                fullWidth
                                name="message"
                                className="contact-us-text"
                                multiline
                                rowsMax="4"
                                margin="normal"
                                onChange={this.onChangeHandler}
                                inputProps={{ maxLength: 2000 }}
                                value={this.state.message}
                            />
                            </Col>
                        <Col sm="12">
                            <Button
                                disabled={this.state.isButtonDisabled}
                                className="primary"
                                onClick={this.onSubmit}
                            >
                                Enquiry Now
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }
}

export default withRouter(EnquiryForm);
