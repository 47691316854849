import axios from 'axios';

export const contactUs = (payload = {}) => {
  // console.log('payload-->', payload)
  return axios.post('https://www.ascend-kilimanjaro.com/api/slack', payload, {
    headers : {
      'Content-Type': 'application/json'
      // 'Content-type': 'application/x-www-form-urlencoded'
    }
  })
  .then(response => {
    // debugger;
    // console.log(response);
  })
  .catch(error => {
    throw error;
  });
}

export const getGeoLocation = () => {
  const IP_FIND_API_KEY = '6c1a6af6-428a-4f05-b522-b7b69f3ca5b1';
  return axios.get(`https://api.ipfind.com/me?auth=${IP_FIND_API_KEY}`)
  .then(response => {
    return response.data;
  })
  .catch(error => {
    throw error;
  });
}
