import { lazy } from "react";
import HelmetHOC from "../../utils/HelmetHOC";

const Terms = lazy(() => import("./Terms"));

const TermsRouteDetails = {
  path: "/terms-and-conditions",
  exact: true,
  render() {
    return HelmetHOC(Terms, {
      title: "Terms and conditions",
      description:
        "Planing a Safari in Africa? We build iternaries for all sorts of Safaris. One of the leading Safari organizers in Kenya & Tanzania. Book your Safari now"
    });
  }
};

export { TermsRouteDetails };
