import { lazy } from "react";
import HelmetHOC from "../../utils/HelmetHOC";

const PageNotFound = lazy(() => import("./PageNotFound"));

const PageNotFoundRouteDetails = {
  path: "/pagenotfound",
  exact: true,
  render() {
    return HelmetHOC(PageNotFound, {
      title: "Page not found",
      description:
        "Planing a Safari in Africa? We build iternaries for all sorts of Safaris. One of the leading Safari organizers in Kenya & Tanzania. Book your Safari now"
    });
  }
};

export { PageNotFoundRouteDetails };
