import React, { Component } from 'react';
import {
  Row,
  Col,
  Modal,
  ModalBody
} from 'reactstrap';
import { withRouter } from 'react-router-dom';

// import Typography from '@material-ui/core/Typography';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import Button from '../Button/Button';
import MButton from '@material-ui/core/Button';
import {
  contactUs,
  getGeoLocation
} from '../../../common/Api/commonApi';
import './ContactDetailsForm.scss';

class ContactDetailsForm extends Component {
  state = {
    name: '',
    email: '',
    contactNumber: '',
    message: '',
    isError: false,
    // isButtonDisabled: true,
    countryCode: 'us',
    // isPhoneNumberInvalid: false
  };

  componentWillMount() {
    this.getCountryCode();
  }

  onEmailChangeHandler = (e) => {
    let email = e.target.value;
    email = email.trim() !== '' ? email : '';

    this.setState({ email });
  }

  getCountryCode = () => {
    getGeoLocation().then(response => {
      const countryCode = response && response.country_code && response.country_code.toLowerCase();

      if (countryCode) {
        this.setState({
          countryCode
        });
      } 
    })
  }

  handleSubmit = () => {
    // if (!this.state.contactNumber) {
    //   this.setState({
    //     isPhoneNumberInvalid: true,
    //     isError: true
    //   });

    //   return;
    // }

    const payload = {
      // name: '',
      email: this.state.email,
      phone: this.state.contactNumber,
      // message: '',
      packageDetails: this.props.packageDetails
    };

    const keysToModify = ['accommodation', 'days', 'parks', 'partyOptions', 'season'];

    // Cleaning up the payload
    keysToModify.forEach((key) => {
      payload.packageDetails[key] = payload.packageDetails[key].filter((data) => {
        return !!data.isSelected;
      });
      payload[key] = payload.packageDetails[key][0].title
    });

    // Deleting packageDetails key
    delete payload.packageDetails;

    contactUs(payload)
      .then(resp => {
        console.log(resp)
        this.props.history.push('/thank-you')
      })
      .catch(error => {
        console.log(error)
        window.scrollTo(0,0)
      });

    // Submit the form
    this.setState({
      isError: false
    });
  }

  render() {
    const d = new Date();
    const currentYear = d.getFullYear();

    return(
      <Modal
        className={this.props.className}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <ModalBody>
          <Row className="contact-modal-body">
            <Col sm="6" className="modal-image">
            </Col>
            <Col sm="6" className="contact-us-form">
              <div className="form-title text-center">
                <span>Get an Instant Quote </span>
              </div>
              {/* <form name="contact-us" noValidate id="contact-us"> */}
              <ValidatorForm
                id="btn-lead-form"
                ref="form"
                onSubmit={this.handleSubmit}
                onError={errors => console.log(errors)}
              >
                <Col sm="12">
                  <TextValidator
                    label="Email"
                    name="email"
                    margin="normal"
                    validators={['required', 'isEmail']}
                    errorMessages={['Please enter an email', 'Please enter a valid email']}
                    onChange={this.onEmailChangeHandler}
                    value={this.state.email}
                    size="small"
                    variant="outlined"
                  />
                </Col>
                <Col sm="12">
                  <PhoneInput
                    country={this.state.countryCode}
                    // If you remove this then UAE and other countries wouldn't appear
                    // regions={['north-america', 'europe', 'oceania', 'asia']}
                    value={this.state.contactNumber}
                    inputClass="phone-number-input"
                    inputProps={{
                      name: 'phone',
                      required: false
                    }}
                    onChange={contactNumber => this.setState({ contactNumber })}
                  />
                  {
                    // this.state.isPhoneNumberInvalid && 
                    // <Typography className="error-text" variant="caption" display="block" gutterBottom>
                    //   Please enter a valid phone number.
                    // </Typography>
                  }
                </Col>
                <div className="discount">
                  <span>*Get an Early bird discount upto 10%.<span> <br />
                  </span>*Offer valid on all bookings till July 15, {currentYear}</span>
                </div>
                <Col sm="12" className="footer-button text-right">
                  <MButton
                    type="button"
                    onClick={this.props.toggle}
                  >
                    Cancel
                  </MButton>
                  <Button
                    className="primary"
                    type="submit"
                    id="btn-lead-submit"
                  >
                    Get Quote
                  </Button>
                </Col>
              {/* </form> */}
              </ValidatorForm>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default withRouter(ContactDetailsForm);
