import { lazy } from "react";
import HelmetHOC from "../../utils/HelmetHOC";

const ThankYou = lazy(() => import("./ThankYou"));

const ThankYouRouteDetails = {
  path: "/thank-you",
  exact: true,
  render() {
    return HelmetHOC(ThankYou, {
      title: "Thank you",
      description:
        "Planing a Safari in Africa? We build iternaries for all sorts of Safaris. One of the leading Safari organizers in Kenya & Tanzania. Book your Safari now"
    });
  }
};

export { ThankYouRouteDetails };
