import React from 'react';
import Button from '../Button/Button';
import { Typography } from '@material-ui/core';

import './Banner.scss';

const Banner = (props) => {
  return (
    <div className="banner_section">
      <div className={props.className ? `banner ${props.className}` : 'banner'} style={props.style}>
        <p>{props.bannerText}</p>
        {
          props.bannerText ?
          <Typography variant="h4">
            {props.bannerSubText}
          </Typography>
          : ''
        }
        {
          props.buttonText ?
          <Button
            onClick={props.buttonHandler}
            className={props.buttonClassName || 'primary'}
          >
            {props.buttonText}
          </Button> : ''
        }
      </div>
    </div>
  );
}

export default Banner;