import React from "react";
import MetaTags from "react-meta-tags";

// Handle tab title and meta tags for SEO
function HelmetHOC(Component, props = {}) {
  return (
    <div>
      <MetaTags>
        <title>{props.title || "Full of Africa"}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.description} />
      </MetaTags>
      <Component {...props} />
    </div>
  );
}

export default HelmetHOC;
