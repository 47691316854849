import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// Initialising Sentry
Sentry.init({
  dsn: "https://2f1b2306d5ae491a9a099ca454ae9741@sentry.io/3571420"
});

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
