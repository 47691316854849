import React  from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { HomeRouteDetails } from "../Home/";
import { ContactUsRouteDetails } from "../ContactUs/";
import { GetQuotesRouteDetails } from "../GetQuotes/";
import { PackageDetailsRouteDetails } from "../PackageDetails/";
import { AboutUsRouteDetails } from "../AboutUs/";
import { PrivacyRouteDetails } from "../Privacy/";
import { TermsRouteDetails } from "../Terms/";
import { ThankYouRouteDetails } from "../ThankYou/";
import { PageNotFoundRouteDetails } from "../PageNotFound/";

const Routes = () => {
  return (
    <main>
      <Switch>
        <Route {...HomeRouteDetails} />
        <Route {...ContactUsRouteDetails} />
        <Route {...GetQuotesRouteDetails} />
        <Route {...PackageDetailsRouteDetails} />
        <Route {...AboutUsRouteDetails} />
        <Route {...PrivacyRouteDetails} />
        <Route {...TermsRouteDetails} />
        <Route {...PageNotFoundRouteDetails} />
        <Route {...ThankYouRouteDetails} />
        <Redirect path="*" to="/pagenotfound" />
      </Switch>
    </main>
  );
};

export default Routes;
