import React from 'react';
import './Footer.scss';

const Footer = () => {
  const date = new Date();

  return (
    <footer>
      <div className="text-center copyright-info">
        &copy; {date.getFullYear()} Ascend Tanzania LTD
      </div>
    </footer>
  );
}

export default Footer;
